import axios from 'axios';
import { NEWSUPPLIERID } from "../../constants";
import { notNull } from '../notNull';
import { isInDebug } from '../isInDebug';
import { modules } from '../debugInfo'

export const sha256 = async (message) => {

    // encode as UTF-8
    const msgBuffer = new TextEncoder('utf-8').encode(message);

    // hash the message
    const hashBuffer = await crypto.subtle.digest('SHA-256', msgBuffer);

    // convert ArrayBuffer to Array
    const hashArray = Array.from(new Uint8Array(hashBuffer));

    // convert bytes to hex string
    const hashHex = hashArray.map(b => ('00' + b.toString(16)).slice(-2)).join('');
    return hashHex;
}

const API = '/api';
const API2 = '/api_v2';

const { hostname, protocol } = window.location;
//var arr = href.split("/");

const runLocal = false; //debug on localhost
//const accept = 'http://139.59.148.157'
//const accept = 'http://localhost:8888'
//const accept = 'https://test.app.trive.io/'
const accept = 'https://test.app.trive.io'

const getEndpoint = (hostname) => {
    switch (hostname) {
        case 'preview.trive.io': return 'https://app.trive.io'
        case 'localhost': return runLocal ? 'http://localhost:8888' : accept
        default: return `${protocol}//${hostname}`
    }
}

const endpoint = getEndpoint(hostname);

const instance = axios.create({
    baseURL: endpoint,
    withCredentials: false,
});

const debug = isInDebug(modules.DATASERVICE);
const token = () => 'Bearer ' + sessionStorage.getItem('token');

const _get = async (route) => {

    try {
        const response = await instance.get(route, {
            headers: {
                Authorization: token(),
            }
        });

        const { data } = response;
        debug && console.log('response received', response)

        return data;
    }
    catch (error) {
        debug && console.log('error received', error)
        debug && console.log('error response', error.response);

        if (error && error.response && error.response.status && error.response.status === 401) {
            // user not authenticated
            console.warn('user not authenticated');
        }

        throw error;
    }
}

const _post = async (route, body, withoutAuthorization = false) => {
    const options = {
        headers: {
            Authorization: token(),
        }
    }

    const response = await instance.post(route, body, !withoutAuthorization && options);
    const { data } = response;
    
    debug && console.log(`POST to ${route} result`, response);
    return data;
}

const _delete = async (route) => {
    const response = await instance.delete(route, {
        headers: {
            Authorization: token(),
        }
    });

    const { data } = response;
    debug && console.log(`DELETE to ${route} result`, response);
    return data;
}

const login = async (login, password, appVersion) => {

    const data = {
        'login': login,
        'password': password,
        clientVersion: appVersion,
        localTime: new Date()
    }

    const response = await instance.post(`${API2}/login`, data, {});

    if (debug) { console.log('login success', response); }

    const mode = response.data.limited && response.data.limited === true ? 1 : 2;

    sessionStorage.setItem('token', response.data.token);
    sessionStorage.setItem('userName', response.data.userName);
    sessionStorage.setItem('mode', mode);

    return response.data;
};

const reset_pwd = async (email) => {
    const data = {
        email: email
    }

    const response = await instance.post(`${API2}/reset-password`, data, {});
    return response;
}

const portal_getOverview = async () => {
    const data = await _get(`${API}/portal/overview`);
    return data.accounts;
}



const portal_login = async (login, password) => {
    const response = await _post(`${API}/portal/login`, {
        login: login,
        password: password
    });
    // return code, timestamp, token, userName, companyName, expiresOn
    const {userName, companyName, token} = response;
    sessionStorage.setItem('token', token);
    sessionStorage.setItem('userInfo', JSON.stringify({userName, companyName, role: 'accountant'}));

    return response;
}

const portal_getAccount = async (accountId) => {
    const data = await _get(`${API}/portal/account/${accountId}/settings`);
    return data.settings;
}

const portal_getDocumentQueue = async (accountId, docType) => {
    const data = await _get(`${API}/portal/account/${accountId}/documents?docType=${docType}`)
    return data;
}

const portal_getDocumentContent = async (accountId, documentId) => {
    const url = `${API}/portal/account/${accountId}/documents/${documentId}`;
    const data = await _get(url);
    const content =  data.content;

    const contentHash = await sha256(content);

    return {content, contentHash}
}

const portal_deleteDocument = async (accountId, documentId) => {
    const url = `${API}/portal/account/${accountId}/documents/${documentId}`;
    const result = await _delete(url);
    return result;
}

const portal_loginToAccount = async (accountId, appVersion) => {
    const request = {
        accountId, 
        clientVersion: appVersion,
        localTime: new Date(),
        nonce: 'todo' //todo
    }

    const url = `${API}/portal/account/${accountId}/login/`
    const response = await _post(url, request);

    const mode = response.limited && response.limited === true ? 1 : 2;

    sessionStorage.setItem('token', response.token);
    sessionStorage.setItem('userName', response.userName);
    sessionStorage.setItem('mode', mode);
    sessionStorage.removeItem('userInfo')

    return response.data;
}

const portal_enableOfflineSync = async (request) => {
    
    const { accountId, folder, root } = request;
    
    notNull(accountId, 'accountId');
    notNull(folder, 'folder');
    notNull(root, 'root');

    const url = `${API}/portal/account/${accountId}/syncconfig/`
    const response = await _post(url, request);

    return response;

}

const portal_updateOfflineSync = async (request) => {
    
    const {accountId} = request;
    const url = `api_v2/portal/account/${accountId}/syncconfig/folders`;

    const response = await _post(url, request);
    return response;
}

const portal_startSync = async (accountId, documentIds = []) => {
    const url = `api/portal/sync/` //${accountId}/syncconfig/folders`;
    
    const request = {
        accountId: accountId,
        documentIds: documentIds
    }

    const response = await _post(url, request);
    return response;    
}

const portal_createAccount = async (dto) => {
    const url = `api/portal/admin/account`;
    const response = await _post(url, dto);
    return response;
}

const portal_startOnboarding = async (accountId) => {
    const url = `api/portal/account/${accountId}/onboarding/`;
    const response = await _post(url, {});
    return response;
}

const searchSupplier = async (text) => {
    if (text.length > 1 || text === '*') {
        const data = await _get(`${API2}/supplier/search/${text}`);
        const filtered = data.filter(item => !item.partyId || item.partyId !== '$new');

        const mappedToKeyValue = filtered.map(item => {
            const canMap = item && item.partyId && item.name;
            return canMap ? { key: item.partyId, displayValue: item.name, lastUsedOn: item.lastUsedOn, score: item.score } : item
        })

        return mappedToKeyValue;
    }

    return []; // don't search without a query
};

const searchCustomer = async (text) => {
    if (text.length > 1 || text === '*') {
        const data = await _get(`${API2}/customer/search/${text}`);
        const filtered = data.filter(item => item.partyId !== '$new');
        const mapped = filtered.map(item => {
            const canMap = item && item.partyId && item.name;

            return canMap ? { key: item.partyId, displayValue: item.name, lastUsedOn: item.lastUsedOn } : null
        })

        console.log(mapped)

        return mapped;
    }
    return []; // don't search without a query
};

const createCustomer = async (customerName, vatId, externalReference, memo) => {

    notNull(customerName, 'customerName');

    const request = {
        customerName,
        vatId,
        externalReference,
        memo,
        dryRun: isInDebug('CreateSupplier')
    }

    return await _post(`${API2}/customer/create/`, request);
}

const createSupplier = async (supplierName, vatId, externalReference, memo) => {

    notNull(supplierName, 'supplierName');

    const request = {
        supplierName,
        vatId,
        externalReference,
        memo,
        dryRun: isInDebug('CreateSupplier')
    }

    return await _post(`${API2}/supplier/create/`, request);
};

const delay = (ms) => new Promise(resolve => setTimeout(resolve, ms));

const getInboxQueue = async () => {
    await delay(500); // Wacht 500 ms voordat de request wordt uitgevoerd
    const response = await _get(`${API}/inbox/queue`);
    return response;
};

const getInboxDetail = async (documentId) => {
    const data = await _get(`${API2}/inbox/${documentId}`);
    const { documentTypeId } = data;

    if (documentTypeId !== 1) {
        console.warn('invalid documentId', data);
        throw new Error('Dit document is reeds verwerkt');
    }

    return data;
};

const annotatePurchaseInvoice = async (data) => {
    console.log('annotatePurchaseInvoice data', data);

    const totalAmount = data.documentAmount ? {
        documentValue: parseFloat(data.documentAmount.replace(/,/, '.')),
        baseValue: parseFloat(data.documentAmount.replace(/,/, '.')),
        exchangeRate: data.exchangeRate,
        documentCurrency: data.currency
    } : null;
    const vatAmount = data.documentVat ? {
        documentValue: parseFloat(data.documentVat.replace(/,/, '.')),
        baseValue: parseFloat(data.documentVat.replace(/,/, '.')),
        exchangeRate: data.exchangeRate,
        documentCurrency: data.currency
    } : null;

    let { supplierId } = data;

    if (supplierId === NEWSUPPLIERID) {
        throw new Error('Supplier needs to be created first!');
    }

    const dryRun = isInDebug('Inbox');
    const annotatedData = { ...data, totalAmount, vatAmount, supplierId, dryRun: dryRun };

    const response = await _post(`${API2}/inbox/purchaseInvoice`, annotatedData);
    return response;
};

const getNotifications = async () => {
    const route = `${API}/document/due`;
    const data = await _get(route);
    return data;
}

const getStatements_localhost = async (request) => {
    const response = await instance.get('http://localhost:8889/api/statements', {
        headers: {
            Authorization: token(),
        }
    });

    const { data } = response;
    return data;
}

const getStatements = async () => {
    if (1 === 2 && isInDebug('Reconciliation')){
        return getStatements_localhost();
    }
    else {
        const route = `${API2}/statement/`;
        const data = await _post(route, {});
        return data;
    }
}

const findMatches = async (request) => {

    const route = `${API2}/match/documents/search`;
    const data = await _post(route, request);

    return {
        ...data,
        request
    }
}

const assignTransactionToDocument = async (request) => {
    notNull(request, 'request');

    const { documentId, amount, transactionReference, statementNumber } = request;

    notNull(documentId, 'request.documentId');
    notNull(amount, 'request.amount');
    notNull(transactionReference, 'request.transactionReference');
    notNull(statementNumber, 'request.statementNumber');

    const route = `${API2}/document/${documentId}`;
    const data = await _post(route, request);

    return {
        ...data,
        request
    }
}

const getUserProfile = async () => {
    const route = `${API}/userprofile`;
    const response = await _get(route);
    return response;
}

const searchDocuments = async (query, page = 1) => {
    const route = `${API2}/search/documents/${query}?page=${page}`;
    const response = await _get(route);
    return response;
}

const getDocumentDetail = async (documentId) => {
    const route = `${API2}/document/${documentId}`;
    const response = await _get(route);
    return response;
}

const getDocumentsForExport = async (body) => {
    const route = `${API2}/export/documents`;
    const response = await _post(route, body);
    return response;
}

const ping = async (body) => {

    const route = `${API}/counters`;
    const response = await _get(route, body);
    return response;
}

const sendFiles = (files, onProgressed) => {


    const _post = async (route, body, config) => {
        const response = await instance.post(route, body, {
            ...config,
            headers: {
                Authorization: token(),
            }
        });

        const { data } = response;
        debug && console.log(`POST to ${route} result`, response);
        return data;
    }

    let data = new FormData();
    files.forEach((file) => {
        data.append('lastModified', file.lastModified);
        data.append('contentType', file.type);
        data.append('size', file.size);
        data.append(file.name, file);
    });

    let config = {
        onUploadProgress: function (progressEvent) {
            let percentCompleted = progressEvent.loaded / progressEvent.total;
            onProgressed(percentCompleted);
        },
    };

    return _post(`${API}/upload/`, data, config);
}

const getDashboard = async (period) => {
    notNull(period, 'period');
    const route = `${API}/dashboard?period=${period}`;
    const data = await _get(route);
    return data;
}

const deleteDocument = async (documentId) => {
    notNull(documentId, 'documentId');
    const route = `${API}/document/${documentId}`;
    const data = await _delete(route);
    return data;
}

const annotateSalesInvoice = async (request) => {
    notNull(request, 'request');
    const route = `${API2}/inbox/salesInvoice`;
    const data = await _post(route, request);
    return data;
}

const annotateReferenceMaterial = async (request) => {
    notNull(request, 'request');
    const route = `${API}/inbox/referenceMaterial`;
    const data = await _post(route, request);
    return data;
}

const createArchive = async (documentIds, description, enrichDocuments) => {

    const request = {
        addDetails: enrichDocuments,
        documentIds: documentIds,
        description: description,
    }
    const route = `${API2}/export`;
    const data = await _post(route, request);
    return data;
}

const getActiveArchives = async () => {
    const route = `${API2}/export/history/`
    const data = await _get(route);
    return data;
}

const getContent = async (documentId) => {
    const route = `${API2}/document/${documentId}/content/`
    const data = await _get(route);
    return data;
}

const registerPayment = async (documentId, paymentDate, paymentMethod, amount) => {

    const request = {
        documentId,
        paymentDate,
        paymentMethod,
        amount
    }

    const route = `${API2}/document/payment`;
    const data = await _post(route, request);
    return data;
}

const addMetadata = async (request) => {
    const route = `${API}/inbox/metadata/`
    const data = await _post(route, request);
    return data;
}

const validateOnboarding = async (request) => {
    const route = `${API2}/onboarding/validate`
    const data = await _post(route, request, true);
    return data;
}

const createAccount = async (request) => {
    const route = `${API2}/onboarding/create-account`
    const data = await _post(route, request, true);

    if (data && data.code === 200) {
        const mode = data.limited && data.limited === true ? 1 : 2;

        sessionStorage.setItem('token', data.token);
        sessionStorage.setItem('userName', data.userName);
        sessionStorage.setItem('mode', mode);
    }

    return data;
}

const getPage = async (slug) => {
    const route = `${API2}/content/`
    const request = {
        slug: slug
    }

    const data = await _post(route, request);
    console.log(data);
    return data;

}

const getUserSettings = () => {
    const data = {
        currentPeriod: 201904
    };
    return data
}

const linkTransaction = async (documentId, transactionId) => {
    const route = `${API2}/transaction/link`;
    const request = {
        dryRun: false,
        transactionId: transactionId,
        documentId: documentId,
        notes: '',
    }

    const data = await _post(route, request);

    if (data && data.code === 200) {
        return data;
    }
}


export const dataservice = {
    login,
    searchSupplier,
    createSupplier,
    annotatePurchaseInvoice,
    getInboxQueue,
    getInboxDetail,
    //getCounters,
    getNotifications,
    findMatches,
    assignTransactionToDocument,
    getStatements,
    getUserProfile,
    searchDocuments,
    getDocumentDetail,
    getDocumentsForExport,
    ping,
    sendFiles,
    getDashboard,
    deleteDocument,
    annotateSalesInvoice,
    searchCustomer,
    createCustomer,
    createArchive,
    getActiveArchives,
    getContent,
    registerPayment,
    annotateReferenceMaterial,
    addMetadata,
    validateOnboarding,
    createAccount,
    getPage,
    getUserSettings,
    linkTransaction,
    portal_getOverview,
    portal_login,
    portal_getAccount,
    portal_getDocumentQueue,
    portal_getDocumentContent,
    portal_deleteDocument,
    portal_loginToAccount,
    portal_enableOfflineSync,
    portal_updateOfflineSync,
    portal_startSync,
    portal_createAccount,
    portal_startOnboarding,
    reset_pwd
};