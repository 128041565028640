// stylesheet constants

const SECONDARY_2 = 'color-secondary-2';
const WHITE = 'color-white';

export const COLOR = {
  white: WHITE,
  secondary_2: SECONDARY_2,
};

export const COLOR_VALUE = {
  primary: '#0C6196',
  primaryLight: '#137cbd',
  secondary: '#99BACB',
  secondaryLight: '#F8FCFE',
  error: '#D8393C',
  warning: '#FF9904',
  success: '#4a9864',
  default: '#444444',
  gray1: 'fc--gray-1',
  gray2: 'fc--gray-2',
  gray3: 'fc--gray-3',
  gray4: 'fc--gray-4',
  gray5: 'fc--gray-5',
  gray6: 'fc--gray-6',
  gray7: 'fc--gray-7',
  gray8: 'fc--gray-8',
  gray9: 'fc--gray-9',
  gray10: 'fc--gray-10',
  inherit: ''
};

export const BUTTON_SIZE = {
  small: 'narrow-s',
  medium: 'narrow-m',
  large: 'narrow-l',
  xl: 'narrow-xl',
  full: 'narrow-0',
};

export const BUTTON_MODE = {
  normal: '',
  inverted: 'is-bordered',
};

export const DISABLED = 'is-disabled';

export const CONTROL_MODE = {
  normal: '',
  isPlain: 'is-plain',
};

export const CONTROL_SIZE = {
  xsmall: 'size-xs',
  small: 'size-s',
  medium: 'size-m',
  large: 'size-l',
  default: '',
};

export const CONTROL_COLOR = {
  default: '',
  warning: 'color-warning',
  success: 'color-success',
  error: 'color-error'  
};

export const STATUS_COLOR = {
  default: 'color-gray-5',
  warning: 'color-warning',
  success: 'color-success',
  error: 'color-error',
  disabled: 'color-disabled'
};

export const CONTROL_STATUS = {
  default: 'status-default',
  success: 'status-success',
  warning: 'status-warning',
  error: 'status-error'
};

export const CONTROL_ICONSIZE = {
  default: '',
  large: 'iconSize-l'
};

export const LABEL_SIZE = {
  small: 'size-s',
  large: 'size-l',
  default: '',
};

export const LOADER_SIZE = {
  xsmall: 'size-xs',
  small: 'size-s',
  large: 'size-l',
  default: '',
};

export const PARAGRAPH_SIZE = {
  default: '',
  xs: 'size-xs',
  small: 'size-s',
  medium: 'size-m',
  large: 'size-l',
  xl: 'size-xl',
};

export const PARAGRAPH_WEIGHT = {
  normal: 'weight-400',
  bold: 'weight-700',
};

export const PARAGRAPH_ALIGNMENT = {
  default: '',
  left: 'align-left',
  center: 'align-center',
  right: 'align-right'
};

export const PARAGRAPH_SPACING = {
  default: '',
  xxl: 'spacing-xxl',
  xl: 'spacing-xl',
  large: 'spacing-l',
  medium: 'spacing-m',
  small: 'spacing-s',
  xs: 'spacing-xs',
  xxs: 'spacing-xxs',
  zero: 'spacing-0',
};

export const HEADING_SIZE = {
  xxs: 'size-xxs',
  xs: 'size-xs',
  small: 'size-s',
  medium: 'size-m',
  large: 'size-l',
  xl: 'size-xl',
};

export const HEADING_WEIGHT = {
  default: 'weight-400',
  bold: 'weight-500',
  bolder: 'weight-700',
};

export const HEADING_SPACING = {
  default: '',
  xxl: 'spacing-xxl',
  xl: 'spacing-xl',
  large: 'spacing-l',
  medium: 'spacing-m',
  small: 'spacing-s',
  xs: 'spacing-xs',
  xxs: 'spacing-xxs',
  zero: 'spacing-0',
};

export const STATUS_TYPE = {
  default: 'bgc--primary-1',
  success: 'bgc--success',
  error: 'bgc--error',
  warning: 'bgc--warning',
  alt: 'bgc--secondary-2',
  empty: 'bgc--gray-7',
};

export const ERROR_CODES = {
  unauthenticated: 'UNAUTHENTICATED',
};

export const NEWSUPPLIERID = '$new';

export const DOCUMENTTYPE_ICONS = [
  { key: 1, value: 'inbox' },
  { key: 2, value: 'send' },
  { key: 3, value: 'move_to_inbox' },
  { key: 4, value: 'folder_shared' }
]

export const CURRENT_PERIOD = {
  label: '2025 - Q1',
  value: 202501,
  year: 2025
}

export const GENERAL_ERROR = 'Er deed zich een fout voor. Contacteer ons als het probleem zich blijft voordoen';

export const FEATURE_ANNOTATE_ENABLED = false;